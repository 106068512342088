export default [

  {
    path: "/products",
    name: "products",
    meta: { title: "products", authRequired: true },
    component: () => import("../views/products/index"),
  },
  {
    path: "/products/add",
    name: "products add",
    meta: { title: "products add", authRequired: true },
    component: () => import("../views/products/form"),
  }
  ,
  {
    path: "/products/edit/:id",
    name: "products edit",
    meta: { title: "products edit", authRequired: true },
    component: () => import("../views/products/form"),
  },
  {
    path: "/products/:id",
    name: "products show",
    meta: { title: "products show", authRequired: true },
    component: () => import("../views/products/show"),
  },
  {
    path: "/categories",
    name: "categories",
    meta: { title: "categories", authRequired: true },
    component: () => import("../views/categories/index"),
  },
  {
    path: "/categories/add",
    name: "categories add",
    meta: { title: "categories add", authRequired: true },
    component: () => import("../views/categories/form"),
  }
  ,
  {
    path: "/categories/edit/:id",
    name: "categories edit",
    meta: { title: "categories edit", authRequired: true },
    component: () => import("../views/categories/form"),
  },

  {
    path: "/brands",
    name: "brands",
    meta: { title: "brands", authRequired: true },
    component: () => import("../views/brands/index"),
  },
  {
    path: "/brands/add",
    name: "brands add",
    meta: { title: "brands add", authRequired: true },
    component: () => import("../views/brands/form"),
  }
  ,
  {
    path: "/brands/edit/:id",
    name: "brands edit",
    meta: { title: "brands edit", authRequired: true },
    component: () => import("../views/brands/form"),
  },
  
  {
    path: "/lables",
    name: "lables",
    meta: { title: "lables", authRequired: true },
    component: () => import("../views/lables/index"),
  },
  {
    path: "/lables/add",
    name: "lables add",
    meta: { title: "lables add", authRequired: true },
    component: () => import("../views/lables/form"),
  }
  ,
  {
    path: "/lables/edit/:id",
    name: "lables edit",
    meta: { title: "lables edit", authRequired: true },
    component: () => import("../views/lables/form"),
  },

  {
    path: "/productOptionCategories",
    name: "product option categories",
    meta: { title: "product option categories", authRequired: true },
    component: () => import("../views/productOptionCategories/index"),
  },
  {
    path: "/productOptionCategories/add",
    name: "product option categories add",
    meta: { title: "product option categories add", authRequired: true },
    component: () => import("../views/productOptionCategories/form"),
  }
  ,
  {
    path: "/productOptionCategories/edit/:id",
    name: "product option categories edit",
    meta: { title: "product option categories edit", authRequired: true },
    component: () => import("../views/productOptionCategories/form"),
  },

  {
    path: "/productOptionTags",
    name: "product option tags",
    meta: { title: "product option tags", authRequired: true },
    component: () => import("../views/productOptionTags/index"),
  },
  {
    path: "/productOptionTags/add",
    name: "product option tags add",
    meta: { title: "product option tags add", authRequired: true },
    component: () => import("../views/productOptionTags/form"),
  }
  ,
  {
    path: "/productOptionTags/edit/:id",
    name: "product option tags edit",
    meta: { title: "product option tags edit", authRequired: true },
    component: () => import("../views/productOptionTags/form"),
  },


  {
    path: "/productOptionNames",
    name: "product option names",
    meta: { title: "product option names", authRequired: true },
    component: () => import("../views/productOptionNames/index"),
  },
  {
    path: "/productOptionNames/add",
    name: "product option names add",
    meta: { title: "product option names add", authRequired: true },
    component: () => import("../views/productOptionNames/form"),
  }
  ,
  {
    path: "/productOptionNames/edit/:id",
    name: "product option names edit",
    meta: { title: "product option names edit", authRequired: true },
    component: () => import("../views/productOptionNames/form"),
  },


  {
    path: "/productRatings",
    name: "product ratings",
    meta: { title: "product ratings", authRequired: true },
    component: () => import("../views/productRatings/index"),
  },
  {
    path: "/productRatings/add",
    name: "product ratings add",
    meta: { title: "product ratings add", authRequired: true },
    component: () => import("../views/productRatings/form"),
  }
  ,
  {
    path: "/productRatings/edit/:id",
    name: "product ratings edit",
    meta: { title: "product ratings edit", authRequired: true },
    component: () => import("../views/productRatings/form"),
  },

  {
    path: "/productReviews",
    name: "product reviews",
    meta: { title: "product reviews", authRequired: true },
    component: () => import("../views/productReviews/index"),
  },
  {
    path: "/productReviews/add",
    name: "product reviews add",
    meta: { title: "product reviews add", authRequired: true },
    component: () => import("../views/productReviews/form"),
  }
  ,
  {
    path: "/productReviews/edit/:id",
    name: "product reviews edit",
    meta: { title: "product reviews edit", authRequired: true },
    component: () => import("../views/productReviews/form"),
  },

  {
    path: "/productOptions",
    name: "product options",
    meta: { title: "product options", authRequired: true },
    component: () => import("../views/productOptions/index"),
  },
  {
    path: "/productOptions/add",
    name: "product options add",
    meta: { title: "product options add", authRequired: true },
    component: () => import("../views/productOptions/form"),
  }
  ,
  {
    path: "/productOptions/edit/:id",
    name: "product options edit",
    meta: { title: "product options edit", authRequired: true },
    component: () => import("../views/productOptions/form"),
  },
]