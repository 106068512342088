export default [

  {
    path: "/blogs",
    name: "blogs",
    meta: { title: "blogs", authRequired: true },
    component: () => import("../views/blogs/index"),
  },
  {
    path: "/blogs/add",
    name: "blogs add",
    meta: { title: "blogs add", authRequired: true },
    component: () => import("../views/blogs/form"),
  }
  ,
  {
    path: "/blogs/edit/:id",
    name: "blogs edit",
    meta: { title: "blogs edit", authRequired: true },
    component: () => import("../views/blogs/form"),
  },

  {
    path: "/banners",
    name: "banners",
    meta: { title: "banners", authRequired: true },
    component: () => import("../views/banners/index"),
  },
  {
    path: "/banners/add",
    name: "banners add",
    meta: { title: "banners add", authRequired: true },
    component: () => import("../views/banners/form"),
  }
  ,
  {
    path: "/banners/edit/:id",
    name: "banners edit",
    meta: { title: "banners edit", authRequired: true },
    component: () => import("../views/banners/form"),
  },

  {
    path: "/footer-links",
    name: "footer-links",
    meta: { title: "footer-links", authRequired: true },
    component: () => import("../views/footerLinks/index"),
  },
  {
    path: "/footer-links/add",
    name: "footer-links add",
    meta: { title: "footer-links add", authRequired: true },
    component: () => import("../views/footerLinks/form"),
  }
  ,
  {
    path: "/footer-links/edit/:id",
    name: "footer-links edit",
    meta: { title: "footer-links edit", authRequired: true },
    component: () => import("../views/footerLinks/form"),
  },

  {
    path: "/footer-categories",
    name: "footer-categories",
    meta: { title: "footer-categories", authRequired: true },
    component: () => import("../views/footerCategories/index"),
  },
  {
    path: "/footer-categories/add",
    name: "footer-categories add",
    meta: { title: "footer-categories add", authRequired: true },
    component: () => import("../views/footerCategories/form"),
  }
  ,
  {
    path: "/footer-categories/edit/:id",
    name: "footer-categories edit",
    meta: { title: "footer-categories edit", authRequired: true },
    component: () => import("../views/footerCategories/form"),
  },

  {
    path: "/userGroups",
    name: "userGroups",
    meta: { title: "user groups", authRequired: true },
    component: () => import("../views/userGroups/index"),
  },
  {
    path: "/userGroups/add",
    name: "userGroups add",
    meta: { title: "user groups add", authRequired: true },
    component: () => import("../views/userGroups/form"),
  }
  ,
  {
    path: "/userGroups/edit/:id",
    name: "userGroups edit",
    meta: { title: "user groups edit", authRequired: true },
    component: () => import("../views/userGroups/form"),
  }

  ,
  {
    path: "/sections",
    name: "sections",
    meta: { title: "Sections", authRequired: true },
    component: () => import("../views/sections/index"),
  }
  ,
  {
    path: "/sections/add",
    name: "sections add",
    meta: { title: "sections add", authRequired: true },
    component: () => import("../views/sections/form"),
  }
  ,
  {
    path: "/sections/edit/:id",
    name: "sections edit",
    meta: { title: "sections edit", authRequired: true },
    component: () => import("../views/sections/form"),
  }
  ,
  {
    path: "/sliders",
    name: "sliders",
    meta: { title: "sliders", authRequired: true },
    component: () => import("../views/sliders/index"),
  },
  {
    path: "/sliders/add",
    name: "sliders add",
    meta: { title: "sliders add", authRequired: true },
    component: () => import("../views/sliders/form"),
  },
  {
    path: "/sliders/edit/:id",
    name: "sliders edit",
    meta: { title: "sliders edit", authRequired: true },
    component: () => import("../views/sliders/form"),
  },
    {
        path: "/blog-categories",
        name: "blog-categories",
        meta: {title: "blog-categories", authRequired: true},
        component: () => import("../views/blog-categories/index"),
    },
    {
        path: "/blog-categories/add",
        name: "blog-categories add",
        meta: {title: "blog-categories add", authRequired: true},
        component: () => import("../views/blog-categories/form"),
    }
    ,
    {
      path: "/blog-categories/edit/:id",
      name: "blog-categories edit",
      meta: {title: "blog-categories edit", authRequired: true},
      component: () => import("../views/blog-categories/form"),
  },
  {
    path: "/blog-categories/edit/:id",
    name: "blog-categories edit",
    meta: {title: "blog-categories edit", authRequired: true},
    component: () => import("../views/blog-categories/form"),
  },
  {
    path: "/profile/",
    name: "edit-profile",
    meta: {title: "Profile", authRequired: true},
    component: () => import("../views/profile"),
  },
  {
    path: "/transactions/",
    name: "transactions",
    meta: {title: "Transactions", authRequired: true},
    component: () => import("../views/transactions"),
  },
  {
    path: "/send/notifications/mail",
    name: "send-notifications-mail",
    meta: {title: "Send Notifications Mail", authRequired: true},
    component: () => import("../views/send/send_notification_mail"),
  },
  {
    path: "/send/notifications/mail/log",
    name: "send-notifications-mail-log",
    meta: {title: "Send Notifications Mail Log", authRequired: true},
    component: () => import("../views/send/index"),
  },
]